import React from 'react';

class Toolbox extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="Toolbox row">
                <button onClick={this.props.onClickSubmit}>
                    Save
                </button>
            </div>
        )
    }
}

export default Toolbox;
