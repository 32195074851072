import React from 'react';

class Infobox extends React.Component {

    render() {
        return (
            <div className="Infobox row" dangerouslySetInnerHTML={{__html: this.props.info}}></div>
        )
    }
}

export default Infobox;
