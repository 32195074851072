import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    withRouter
} from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
      <Router >
          <Route path="/:id/:game?" >
            <App
                id={ document.getElementById('app-hash') ? document.getElementById('app-hash').value : null}
                instance={ document.getElementById('instance') ? document.getElementById('instance').value : null }
                game={ document.getElementById('game') ? document.getElementById('game').value : null }
            />
          </Route>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
