import React from 'react';
import ImageStory from './ImageStory';
import $ from 'jquery';

import {socket} from './App';

class ImageGallery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: props.images,
            error: null,
            updateTime: Date.now()
        };

        this.handleImageMouseOver = this.handleImageMouseOver.bind(this);
        this.handleImageDeselect = this.handleImageDeselect.bind(this);
        this.scroll = this.scroll.bind(this);
    }

    componentDidMount() {

        socket.emit("getImages", {
            visualizerId: this.props.visualizerId,
            instance: this.props.instance,
            game: this.props.game
        });

        socket.on("updateImages", ({allImages, instance, game, visualizerId}) => {

            if (this.props.visualizerId == visualizerId && allImages) {
                this.setState({'images': allImages});
            }

        });
    }

    componentDidUpdate() {
        if (this.state.images.length === 0) {
           this.setState({'images': this.props.images});
        }

        let selectedImages = this.getSelectedImages(this.state.images);

        this.props.onchange(selectedImages);
    }

    scroll(direction){
        let far = $( '.image-container' ).width()/2*direction;
        let pos = $('.image-container').scrollLeft() + far;
        $('.image-container').animate( { scrollLeft: pos }, 1000)
    }

    toggleImage(index) {
        let images = this.state.images;
        let selectedImages = images.filter((img) => img.selected).map((img) => img.src);

        if ( !images[index].selected && selectedImages.length == 3 ) {
            this.setState({error: 'You are allowed to select up to 3 images'});
            return;
        }

        images[index].selected = !images[index].selected;

        if ( !images[index].selected ) {
            images[index].order = null;
        } else {
            images[index].order = Date.now();
        }
        socket.emit('updateImages', {
            allImages: images,
            visualizerId: this.props.visualizerId,
            instance: this.props.instance,
            game: this.props.game
        } );

        this.setState({ images: images, error: null });
    }

    getAllImages() {
        let wrapStyle = {
            backgroundColor: '#C8B4B4',
            cursor: 'pointer',
            margin: 4,
            position: 'relative',
            width: 100,
            height: 100,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        };
        let markStyle = {
            width: 20,
            height: 20,
            backgroundColor: '#f44336',
            color: '#fff',
            padding: 0,
            textAlign: 'center',
            position: 'absolute',
            borderRadius: '50%',
            top: 4,
            left: 4,
        }

        const images = this.state.images.map((img, index) => {
            return (
                <div
                    key={index}
                    style={{ ...wrapStyle, backgroundImage: `url(${img.src})` }}
                    onClick={() => this.toggleImage(index)}>

                    { img.selected
                        ? <div style={markStyle}>&#10004;</div>
                        : null
                    }
                </div>
            )
        })

        return <div style={{'display': 'inline-flex'}}>
            <a className="prev" onClick={this.scroll.bind(null,-1)}>
                <img src={'assets/images/prev_64.png'}
                     style={{display: 'inline', float: 'left', margin: '40px auto'}} />
            </a>
            <div className="image-container" style={{
                overflowX: 'scroll',
                width: '95%',
                display: '-webkit-inline-box',
                justifyContent: 'space-between',
                margin: '22px auto',
                float: 'left'
            }}>
                {images}
            </div>
            <a className="next" onClick={this.scroll.bind(null,1)}>
                <img src={'assets/images/next_64.png'}
                     style={{display: 'inline', float: 'left', margin: '40px auto'}} />
            </a>
        </div>
    }

    handleImageMouseOver(event, img) {
        // show delete button
        event.target.nextSibling.style.display = 'block';
    }

    handleImageDeselect(event, img, param) {
        this.state.images.find( function(value, index, array) {
            if ( typeof value !== 'undefined' ) {
                if (value.selected === true && value.src === img) {
                    this.state.images[index].selected = false;
                    socket.emit('updateImages', {
                        allImages: this.state.images,
                        visualizerId: this.props.visualizerId,
                        instance: this.props.instance,
                        game: this.props.game
                    } );
                    this.forceUpdate()
                }
            }
            return false;
        }, this);

    }

    handleImageComment(image, comment) {
        image.comment = comment;
        this.state.images.find( function(element, index, array){
            if (element.src === image.src) {
                this.state.images[index].comment = comment;
            }
        }, this);

        socket.emit('updateImages', {
            allImages: this.state.images,
            visualizerId: this.props.visualizerId,
            instance: this.props.instance,
            game: this.props.game
        } );
        this.forceUpdate();
    }

    getSelectedImages(images) {
        return images.slice(0, images.length).sort(function(a,b){

            if ( a.order && !b.order ) {
                return 1;
            } else if ( !a.order && b.order) {
                return -1;
            } else if ( !a.order && !b.order ) {
                return 0;
            }

            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;


            return 0;
        }).filter((img) => img.selected);
           // .map((img) => {'src': img.src, 'comment': img.comment});

    }

    render() {
        let selectedImages = this.getSelectedImages(this.state.images);

        return (
            <div style={{ paddingBottom: 22 }} className="row">
                {/*<Header/>*/}

                <div className="Center col">
                    <div className="Heading">{this.props.heading}</div>

                    <div className="row">
                        {this.getAllImages()}
                    </div>
                    {
                        this.state.error
                            ?
                            <div className="alert alert-danger">{this.state.error}</div>
                            :
                            ''
                    }
                    <div ref={this.props.pdfRef} >
                        <div className="ChoiceArea" id="ChoiceArea">
                            <ImageStory
                                onImageSelect={(e, img) => { window.open(img) } }
                                onImageDeselect={(e, img, param) => this.handleImageDeselect(e, img, param)}
                                onImageMouseOver={(e, img) => this.handleImageMouseOver(e, img)}
                                onImageCommentClick={(e, img) => this.handleImageComment(e, img)}
                                isImageText={this.props.isImageText}
                                // rootStyle={{ margin: '0 auto' }}
                                onLoad={() => console.log('Images loaded')}
                                images={selectedImages}
                            />
                        </div>
                    </div>
                </div>

                {/*<Footer/>*/}
            </div>
        )
    }
}

const Header = function() {
    let style = {
        background: '#f5f5f5',
        padding: '0 12px',
        borderBottom: 'solid 1px #eee',
    };

    return (
        <header style={style}>
            <div style={{margin: '0 auto', maxWidth: 1200, display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 52}}>
                <span style={{fontFamily: 'monospace', fontSize: '22px'}}>React smart gallery</span>
                <a href='https://github.com/gauravchl/react-smart-gallery' target='_blank'>
                    <OctoCat style={{float: 'right', fill: '#424242'}}/>
                </a>
            </div>
        </header>
    )
}


const Footer = function() {
    let style = {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#fff',
        textAlign: 'center',
        display: 'flex',
        fontFamily: 'monospace',
        alignItems: 'center',
        justifyContent: 'center',
        height: 42,
    }
    return (
        <footer style={style}>
            <CamLogo /><a style={{color: '#757575'}} href='https://unsplash.com' target='_blank'>images powered by unsplash</a>
        </footer>
    )
}


const OctoCat = function(props) {
    return (
        <svg height="22" version="1.1" viewBox="0 0 16 16" width="22" {...props}>
            <path fillRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path>
        </svg>
    )
}

const CamLogo = function() {
    return (
        <svg width="30px" height="16px" viewBox="0 0 104 90" version="1.1" fill='#616161'>
            <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
                <g id="365-photos-later"  transform="translate(1.000000, 0.000000)">
                    <path d="M51.2,36.1 C42.7,36.1 35.7,43 35.7,51.6 C35.7,60.2 42.7,67 51.2,67 C59.7,67 66.7,60.1 66.7,51.5 C66.7,42.9 59.8,36.1 51.2,36.1 L51.2,36.1 Z M89,13.7 L77,13.7 L74.3,6.4 C72.9,2.9 68.7,0 65,0 L37.5,0 C33.7,0 29.6,2.9 28.2,6.4 L25.5,13.7 L13.5,13.7 C5.9,13.7 -0.2,19.9 -0.2,27.4 L-0.2,75.5 C-0.2,83.1 6,89.2 13.5,89.2 L89,89.2 C96.6,89.2 102.7,83 102.7,75.5 L102.7,27.5 C102.8,19.9 96.6,13.7 89,13.7 L89,13.7 Z M51.2,75.6 C37.9,75.6 27.2,64.8 27.2,51.6 C27.2,38.4 38,27.6 51.2,27.6 C64.5,27.6 75.2,38.4 75.2,51.6 C75.2,64.8 64.5,75.6 51.2,75.6 L51.2,75.6 Z" id="Shape"/>
                </g>
            </g>
        </svg>
    )
}

export default ImageGallery;
