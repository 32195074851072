import logo from './logo.svg';
import './App.scss';
import moment from 'moment';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    withRouter
} from 'react-router-dom';

import ImageGallery from './ImageGallery';
import Infobox from './Infobox';
import Toolbox from './Toolbox';
import Timer from './Timer';
import React, { Component, useState } from "react";
import axios from "axios";
import { exportComponentAsPNG } from 'react-component-export-image';
import * as htmlToImage from 'html-to-image';
import { toPng } from 'html-to-image';

import socketIOClient from "socket.io-client";

let {REACT_APP_STICKITY_URL, REACT_APP_STICKITY_IMAGE_URL, REACT_APP_VISCOLSERVER_URL} = process.env;
var socket = socketIOClient(REACT_APP_VISCOLSERVER_URL);


class App extends Component {

    constructor(props) {
        super(props);
        const uid = Date.now();
        const ref = React.createRef();
        const { id } = this.props.id ? this.props : this.props.match.params;
        const { game } = this.props.game ? this.props : this.props.match.params;
        const { instance } = this.props.instance ? this.props : this.props.match.params;
        this.state = {
            stickity: [],
            uid: uid,
            ref: ref,
            visualizerId: id,
            game: game,
            instance: instance,
            countdown: null,
            startTime: null
        }
    }

    componentDidMount() {
        socket.emit('create',this.state.instance + '-' + this.state.game)
        if ( this.state.visualizerId ) {
            REACT_APP_STICKITY_URL += this.state.visualizerId;
        }

        axios.get(REACT_APP_STICKITY_URL)
            .then( (response) => {

                let { countdown } = response.data;

                this.setState({
                    stickity: response.data,
                    //visualizerId: id,
                    countdown: countdown
                });
            })
            .catch(error => {
                console.log("ERROR::", error.message)
            });
    }

    saveSelectedImages = (selectedImages) => {
        this.selectedImages = selectedImages;
    }

    handleClickSubmit = () => {
        const filename = this.state.stickity.header + ' ' + this.state.uid;
        const options = {
            orientation: 'landscape',
        };
        //let ret = exportComponentAsPNG(this.state.ref, {fileName: filename + '.png'})

        let selectedImages = this.selectedImages;

        htmlToImage

            .toPng(this.state.ref.current)

            .then(function (dataUrl) {
                var img = new Image();
                img.src = dataUrl;
                return img.src;
            })
            .then(function(imgSrc){

                //post
                axios.post(REACT_APP_STICKITY_URL,{
                    "images": JSON.stringify(selectedImages),
                    "screenshot": imgSrc
                }).then((response) => {
                    console.log(response);
                }, (error) => {
                    console.log(error);
                })
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });

    }


    render(){
        const now = moment();
        let timeTillDate = 0;
        let countdown = null;
        let heading = '';
        let info = '';
        let isImageText = null;
        let images = [];
        if (this.state.stickity !== null) {
            heading = this.state.stickity.header;
            timeTillDate = this.state.stickity.countdown > 0 ? now.add(this.state.stickity.countdown, 'm') : 0;
            info = this.state.stickity.info;
            isImageText = this.state.stickity.is_image_text;
            console.log('isImageText', isImageText);
            if (this.state.stickity.images) {
                images = this.state.stickity.images.map((img, index) => {
                    return {
                        'id': img.id,
                        'src': REACT_APP_STICKITY_IMAGE_URL + img.id,
                        'selected': false,
                        'comment': ''
                    };
                });
            }
        }

        let imageGallery = <ImageGallery
                            visualizerId={this.state.visualizerId}
                            instance={this.state.instance}
                            game={this.state.game}
                            onchange={(e, selectedImages) => this.saveSelectedImages(e, selectedImages)}/>
        if (images) {
            imageGallery = <ImageGallery
                            visualizerId={this.state.visualizerId}
                            instance={this.state.instance}
                            game={this.state.game}
                            pdfRef={this.state.ref}
                            heading={heading}
                            isImageText={isImageText}
                            images={images}
                            onchange={(e, selectedImages) => this.saveSelectedImages(e, selectedImages)}/>
        }

        return (

            <div className="App container-fluid">

                <div className="Header row rounded-top">&nbsp;</div>
                <div className="Main row">
                    <div className="Tools col col-lg-1">
                        <Infobox info={info} />
                        <Toolbox onClickSubmit={(e) => this.handleClickSubmit()} />
                        {
                            timeTillDate != 0
                            ?
                                <Timer
                                    visualizerId={this.state.visualizerId}
                                    instance={this.state.instance}
                                    game={this.state.game}
                                    countdown={this.state.countdown}
                                    timeTillDate={timeTillDate}
                                    timeFormat="MM DD YYYY, h:mm a" />
                                :
                                null
                        }

                    </div>
                    <div className="Gallery col col-lg-11">
                        {imageGallery}
                    </div>
                </div>

            </div>

        );
    }

}

export default withRouter(App);
export {socket};
